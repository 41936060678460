import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import config from '../../../styles/setting/_config.json';

const { color } = config;

function Loading() {
    const canvasRef = useRef(null);
    const [animationComplete, setAnimationComplete] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        const updateCanvasSize = () => {
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
        };

        const drawCircle = () => {
            const w = canvas.clientWidth;
            const h = canvas.clientHeight;

            const centerX = w / 2 - 5;
            const centerY = h / 2 - 1;
            const radius = 80;
            const lineWidth = 2;
            const speed = 0.1;

            const startAngle = Math.PI * 2.25;
            const endAngle = Math.PI * 0.75;

            let angle = startAngle;

            const animate = () => {
                context.clearRect(0, 0, w, h);

                context.beginPath();
                context.arc(centerX, centerY, radius, startAngle, angle);
                context.lineWidth = lineWidth;
                context.strokeStyle = color.white;
                context.stroke();

                angle += speed;

                if (angle >= startAngle + Math.PI * 2.25) {
                    angle = endAngle;
                    setAnimationComplete(true);
                } else {
                    requestAnimationFrame(animate);
                }
            };

            updateCanvasSize();
            animate();
        };

        window.addEventListener('resize', updateCanvasSize);
        drawCircle();

        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, []);

    return (
        <Sdiv
            key="load"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
        >
            <canvas ref={canvasRef} />
            <StyledDiv show={!animationComplete ? 0 : 1} />
        </Sdiv>
    );
}

const Sdiv = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    background-color: ${color.gray};
    z-index: 999;

    canvas {
        width: 100%;
        height: 100%;
        display: block;
    }
`;

const StyledDiv = styled(motion.div)`
    position: absolute;
    width: 22px;
    height: 22px;
    top: calc(50% + 60px);
    left: calc(50% + 64px);
    border-radius: 50%;
    background: ${color.white};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.6s ease-in-out;
`;

export default Loading;
