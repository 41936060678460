import React, { useContext } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Datecontent from '../../templates/datecontent/Datecontent';
import Datecolumnscontent from '../../templates/datecolumnscontent/Datecolumnscontent';
import Trans from '../../../providers/context/trans/Trans';

export function Home() {
    const { trans } = useContext(Trans);

    return (
        <AnimatePresence>
            {trans || (
                <Sdiv
                    key=""
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        duration: 0.4,
                    }}
                >
                    <Datecontent />
                    <Datecolumnscontent />
                </Sdiv>
            )}
        </AnimatePresence>
    );
}

const Sdiv = styled(motion.div)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export default Home;
