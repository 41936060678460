import React from 'react';
import styled from 'styled-components';
import config from '../../../styles/setting/_config.json';

const { color } = config;

function Column({ children }) {
    return <Sdiv>{children}</Sdiv>;
}
const Sdiv = styled.div`
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    color: ${color.white};
    width: 300px;
    height: 50px;
    line-height: 50px;
    background-color: ${color.white};
    border: 1px solid ${color.black};
    transition: 0.4s;
    position: relative;
    display: flex;
    align-items: center;
    &:hover,
    &:active {
        background-color: ${color.rightGray};
    }
`;

export default Column;
