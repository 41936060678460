import { atom } from 'recoil';

const Mode = atom({
    key: 'Mode',
    default: {
        page: 'login',
        isHost: false,
    },
});

export default Mode;
