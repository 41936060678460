import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import Loginform from '../../molecules/loginform/Loginform';
import Logo from '../../atoms/svg/Logo';
import Message from '../../atoms/message/Message';
import Editarea from '../../molecules/editarea/Editarea';
import Mode from '../../../providers/mode/Mode';
import Tryangle from '../../atoms/decoration/Tryangle';

const Logincontent = React.memo(() => {
    const mode = useRecoilValue(Mode);

    const [message, setMessage] = useState({});
    const handleMessageSubmit = (newMessage) => {
        setMessage(newMessage);
    };
    return (
        <Sarticle>
            <Message message={message} />
            <Logo size="112" />
            <Loginform onSubmit={handleMessageSubmit} />
            {mode.page === 'login' ? <Tryangle /> : <Editarea />}
        </Sarticle>
    );
});
const Sarticle = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -15%;
`;
export default Logincontent;
