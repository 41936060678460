import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Column from '../../atoms/column/Column';
import config from '../../../styles/setting/_config.json';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';

const { color } = config;
const Dateinput = React.memo(() => {
    const { changeDate } = useContext(Attendancecontext);
    const [entry, setEntry] = useState('');
    const [exit, setExit] = useState('');
    const [breakTime, setBreakTime] = useState(0);
    useEffect(() => {
        if (changeDate) {
            if (changeDate.entryTime) {
                setEntry(changeDate.entryTime);
            }
            if (changeDate.exitTime) {
                setExit(changeDate.exitTime);
            }
            if (changeDate.breakTime) {
                setBreakTime(changeDate.breakTime);
            }
        }
    }, [changeDate]);

    const handleEntryChange = (event) => {
        setEntry(event.target.value);
    };
    const handleExitChange = (event) => {
        setExit(event.target.value);
    };
    const handleBreakChange = (event) => {
        setBreakTime(event.target.value);
    };

    return (
        <Sdl>
            <Column>
                <label htmlFor="entry">
                    <dt>出勤</dt>
                    <dd>
                        <input
                            value={entry}
                            onChange={handleEntryChange}
                            type="time"
                            id="entry"
                            name="entry"
                        />
                    </dd>
                </label>
            </Column>
            <Column>
                <label htmlFor="exit">
                    <dt>退勤</dt>
                    <dd>
                        <input
                            value={exit}
                            onChange={handleExitChange}
                            type="time"
                            id="exit"
                            name="exit"
                        />
                    </dd>
                </label>
            </Column>
            <Column>
                <label htmlFor="break">
                    <dt>休憩</dt>
                    <dd>
                        <select
                            onChange={handleBreakChange}
                            value={breakTime}
                            id="break"
                            name="break"
                        >
                            <option value="0">0min</option>
                            <option value="15">15min</option>
                            <option value="30">30min</option>
                            <option value="45">45min</option>
                        </select>
                    </dd>
                </label>
            </Column>
        </Sdl>
    );
});

const Sdl = styled.dl`
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    dt {
        position: absolute;
        left: 25px;
        color: ${color.black};
        font-size: 1.4rem;
        font-weight: 600;
        font-family: 'Kiwi Maru', serif;
    }
    label {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    dd {
        width: 100%;
        letter-spacing: 0.3em;
        font-size: 1.4rem;
        font-weight: bold;
        font-family: 'M PLUS 2', sans-serif;
        text-align: center;
    }

    select {
        letter-spacing: 0.3em;
        text-align: center;
        color: ${color.black};
        font-size: 1.4rem;
        font-weight: bold;
        font-family: 'M PLUS 2', sans-serif;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: auto;
        white-space: normal;
        margin: 0;
        border: 0;
        outline: none;
        border-radius: 0;
        padding: 0;
        background: transparent;
        &::-ms-expand {
            display: none;
        }
    }

    input[type='time'] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        font: inherit;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        display: none;
    }
`;
export default Dateinput;
