import React, { useEffect, useState } from 'react';
import Rectangle from '../../atoms/decoration/Rectangle';
import Calendar from '../../atoms/calendar/Calendar';
import Logo from '../../atoms/svg/Logo';

const Sidebar = React.memo(() => {
    const [day, setDay] = useState('');
    const [week, setWeek] = useState('');
    const setDate = async () => {
        try {
            const phpPath = '../php//date.php';
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { set: 'set' } }),
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setDay(data.day);
                setWeek(data.week);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };

    useEffect(() => {
        setDate();
    }, []);

    return (
        <>
            <Rectangle />
            <Calendar day={day} wday={week} />
            <Logo size="45" />
        </>
    );
});

export default Sidebar;
