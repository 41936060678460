import React from 'react';
import styled from 'styled-components';
import Dateinput from '../../molecules/dateinput/Dateinput';
import CheckBtn from '../../atoms/button/CheckBtn';
import config from '../../../styles/setting/_config.json';

const { color } = config;
function Inputcontent() {
    return (
        <Sdiv>
            <Dateinput />
            <CheckBtn />
        </Sdiv>
    );
}
const Sdiv = styled.div`
    width: fit-content;
    margin: auto;
    border-top: 1px ${color.black} solid;
    height: 65%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    padding-top: 50px;
`;

export default Inputcontent;
