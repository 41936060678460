import React from 'react';
import styled from 'styled-components';
import config from '../../../styles/setting/_config.json';

const { color } = config;
const Datatext = React.memo(({ day, time, total }) => {
    return (
        <Sdiv>
            <p className="day">{day}</p>
            <div className="dateWrap">
                <p className="time">{time}</p>
                <p className="total">{total}</p>
            </div>
        </Sdiv>
    );
});
const Sdiv = styled.div`
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    width: 100%;

    p {
        color: ${color.black};
        font-size: 1.4rem;
    }
    .day {
        width: 2.3em;
        white-space: nowrap;
        text-align: right;
        font-weight: 500;
        font-family: 'Kiwi Maru', serif;
    }
    .dateWrap {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .time {
        letter-spacing: 0.05em;
        font-weight: normal;
        font-family: 'M PLUS 2', sans-serif;
    }
    .total {
        letter-spacing: 0.05em;
        font-weight: normal;
        font-family: 'M PLUS 2', sans-serif;
    }
`;
export default Datatext;
