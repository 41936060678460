import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Tryangle from '../../atoms/decoration/Tryangle';
import Editbtn from '../../atoms/editbtn/Editbtn';
import Addbtn from '../../atoms/editbtn/Addbtn';
import Mode from '../../../providers/mode/Mode';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';

const Editarea = React.memo(() => {
    const mode = useRecoilValue(Mode);
    const { changeDate } = useContext(Attendancecontext);
    let rule = '';
    switch (mode.page) {
        case 'home':
            rule = 'add';
            break;
        case 'register':
            rule = 'buck';
            break;
        default:
            rule = 'add';
    }

    const [isView, setIsView] = useState(false);
    useEffect(() => {
        if (isView) {
            setIsView(false);
        }
    }, [mode]);
    return (
        <Tryangle>
            {mode.page === 'input' ? (
                <>
                    <Editbtn rule="pen" isView={isView} setIsView={setIsView} />
                    <Editbtn rule="ban" isView={isView} setIsView={setIsView} />
                    {changeDate && <Editbtn rule="trash" isView={isView} setIsView={setIsView} />}
                </>
            ) : (
                <Addbtn rule={rule} />
            )}
        </Tryangle>
    );
});

export default Editarea;
