import styled from 'styled-components';
import { rgba } from 'polished';
import config from './setting/_config.json';

const { color } = config;
const boxShadow = rgba(color.black, 0.35);

const BSbutton = styled.button`
    cursor: pointer;
    user-select: none;
    border-radius: 50px;
    color: ${color.white};
    width: 200px;
    height: 58px;
    font-size: 1.6rem;
    text-align: center;
    line-height: 58px;
    font-family: 'M PLUS 2', sans-serif;
    background-color: ${color.black};
    box-shadow: 0 5px 15px ${boxShadow};
    letter-spacing: 0.1em;
`;

export default BSbutton;
