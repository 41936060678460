import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Logininput from '../../atoms/logininput/Logininput';
import Btn from '../../atoms/button/Btn';
import Mode from '../../../providers/mode/Mode';

function Loginform({ onSubmit }) {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [mode, setMode] = useRecoilState(Mode);
    let phpPath = '';

    let rule = '';
    if (mode.page === 'login') {
        phpPath = '../php//login.php';
        rule = 'LOGIN';
    } else if (mode.page === 'register') {
        phpPath = '../php//register.php';
        rule = 'SIGN UP';
    }
    useEffect(() => {
        if (mode.page === 'login') {
            const phpCheckLoginPath = '../php//check-login.php';
            const checkLoginStatus = async () => {
                try {
                    const response = await fetch(phpCheckLoginPath, {
                        credentials: 'include',
                    });
                    if (response.ok) {
                        const data = await response.json();
                        if (data.isLoggedIn) {
                            if (data.hosts) {
                                setMode({ ...mode, page: 'home', isHost: true });
                            } else {
                                setMode({ ...mode, page: 'home' });
                            }
                        } else {
                        }
                    }
                } catch (error) {
                    console.log('エラー:', error);
                }
            };
            checkLoginStatus();
        }
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(phpPath, {
                method: 'POST',
                body: new FormData(e.target),
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success) {
                onSubmit(data.success);
                setUser('');
                setPassword('');
                if (mode.page === 'login') {
                    window.location.reload();
                }
            } else {
                onSubmit(data.error);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };
    const handleUserChange = (event) => {
        setUser(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    return (
        <form action="" method="post" onSubmit={handleFormSubmit} style={{ marginTop: '56px' }}>
            <Logininput
                userValue={user}
                passwordValue={password}
                onUserChange={handleUserChange}
                onPasswordChange={handlePasswordChange}
            />
            <Btn btnRole={rule} />
        </form>
    );
}

export default Loginform;
