import React, { useContext, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Column from '../../atoms/column/Column';
import Datatext from '../../atoms/datatext/Datatext';
import Mode from '../../../providers/mode/Mode';
import Userdatatext from '../../atoms/datatext/Userdatatext';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';
import Edit from '../../../providers/edit/Edit';
import Trans from '../../../providers/context/trans/Trans';

const Datecolumn = React.memo((props) => {
    const { setChangeDate } = useContext(Attendancecontext);
    const [isRequesting, setIsRequesting] = useState(false);
    const [mode, setMode] = useRecoilState(Mode);
    const setEdit = useSetRecoilState(Edit);
    const divRef = useRef(null);
    let content = null;
    const { trans, setTrans } = useContext(Trans);

    const handleClick = async () => {
        if (mode.isHost) {
            return;
        }
        if (isRequesting) {
            return;
        }
        setIsRequesting(true);
        try {
            const { id } = props;
            const phpPath = '../php//edit.php';
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { edit: id } }),
                credentials: 'include',
            });

            const data = await response.json();
            if (data) {
                const attendance = {
                    id: data.id,
                    user_id: data.user_id,
                    date: data.date,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    entryTime: data.entryTime,
                    exitTime: data.exitTime,
                    breakTime: data.breakTime,
                };
                Promise.resolve()
                    .then(() => {
                        setChangeDate(attendance);
                    })
                    .then(() => {
                        setEdit(true);
                        if (!trans) {
                            setTrans(true);
                        }
                        setTimeout(() => {
                            setMode({ ...mode, page: 'input' });
                            setTrans(false);
                        }, 600);
                    });
            }
        } catch (error) {
            console.log('エラー:', error);
        } finally {
            setIsRequesting(false);
        }
    };

    if (mode.isHost) {
        const { user, days, total } = props;
        content = <Userdatatext user={user} days={days} total={total} />;
    } else {
        const { day, time, total } = props;
        content = <Datatext day={day} time={time} total={total} />;
    }

    return (
        <div
            ref={divRef}
            style={{ zIndex: '1', cursor: 'pointer' }}
            onClick={handleClick}
            role="button"
            tabIndex={0}
        >
            <Column>{content}</Column>
        </div>
    );
});

export default Datecolumn;
