import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import Yrnmo from '../../atoms/yrnmo/Yrnmo';
import Relmonth from '../../molecules/relmonth/Relmonth';
import Mode from '../../../providers/mode/Mode';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';
import Edit from '../../../providers/edit/Edit';
import Load from '../../../providers/context/load/Load';

function Date() {
    const [year, setYear] = useState('');
    const [number, setNumber] = useState('');
    const [month, setMonth] = useState('');
    const [isRequesting, setIsRequesting] = useState(false);
    const mode = useRecoilValue(Mode);
    const edit = useRecoilValue(Edit);
    const [selectedDate, setSelectedDate] = useState('');
    const { dateEvent, changeDate } = useContext(Attendancecontext);
    const { setLoad } = useContext(Load);

    const sendDataToPHP = async (date) => {
        try {
            const phpPath = '../php//date.php';
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { date } }),
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setYear(data.year);
                setMonth(data.month);
                setNumber(data.number);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };

    const handleDateChange = (event) => {
        const { value } = event.target;
        setSelectedDate(value);
        sendDataToPHP(value);
    };

    const handleMonthChange = async (num) => {
        if (isRequesting) {
            return;
        }
        setIsRequesting(true);
        try {
            const phpPath = '../php//date.php';
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { yrnmo: 'yrnmo' } }),
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setYear(data.year);
                setMonth(data.month);
                setNumber(data[num]);
            }
        } catch (error) {
            console.log('エラー:', error);
        } finally {
            setIsRequesting(false);
        }
    };
    const setDate = async () => {
        try {
            const phpPath = '../php//date.php';
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { set: 'set' } }),
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setSelectedDate(data.date);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };

    useEffect(() => {
        if (edit || mode.page === 'input') {
            return;
        }
        setDate();
    }, []);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (mode.page === 'home') {
            return;
        }
        if (edit && changeDate) {
            setYear(changeDate.year);
            setMonth(changeDate.month);
            setNumber(changeDate.day);
            setSelectedDate(changeDate.date);
        } else {
            const doAsyncWork = async () => {
                await setDate();
                handleMonthChange('day');
            };
            doAsyncWork();
        }
    }, [changeDate]);

    useEffect(() => {
        if (isFirstRender.current || mode.page === 'input') {
            isFirstRender.current = false;
            return;
        }
        handleMonthChange('number');
        setTimeout(() => {
            setLoad(false);
        }, 2000);
    }, [dateEvent]);

    return (
        <Sdiv>
            <label htmlFor="date">
                <Yrnmo year={year} number={number} month={month} />
                {mode.page === 'input' ? (
                    <input
                        value={selectedDate}
                        onChange={handleDateChange}
                        type="date"
                        id="date"
                        name="date"
                        max=""
                    />
                ) : (
                    <Relmonth />
                )}
            </label>
        </Sdiv>
    );
}

const Sdiv = styled.div`
    position: relative;
    z-index: 1;

    label {
        input {
            z-index: 999;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            opacity: 0;
            border: none;
            padding: 0;
            background: none;
            box-shadow: none;
            outline: none;
            &:hover,
            &:focus,
            &:active {
                background: none;
                box-shadow: none;
            }
        }
    }
`;

export default Date;
