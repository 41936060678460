import React from 'react';
import styled from 'styled-components';
import config from '../../../styles/setting/_config.json';
import BSbutton from '../../../styles/BSbutton';

const { color } = config;
const CheckBtn = React.memo(() => {
    return <Sbutton type="submit" />;
});

const Sbutton = styled(BSbutton)`
    z-index: 1;
    &::after {
        content: '';
        border-left: ${color.white} 3px solid;
        border-bottom: ${color.white} 3px solid;
        width: 23px;
        height: 13px;
        display: inline-block;
        transform: translateY(-3px) rotate(-45deg);
    }
`;

export default CheckBtn;
