import React from 'react';
import styled from 'styled-components';
import config from '../../../styles/setting/_config.json';

const { color } = config;
const Userdatatext = React.memo(({ user, days, total }) => {
    return (
        <Sdiv>
            <p className="user">{user}</p>
            <div className="dataWrap">
                <p className="days">{days}</p>
                <p className="total">{total}</p>
            </div>
        </Sdiv>
    );
});
const Sdiv = styled.div`
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    width: 100%;
    p {
        color: ${color.black};
        font-size: 1.4rem;
    }
    .user {
        font-weight: 500;
        font-family: 'Kiwi Maru', serif;
    }
    .dataWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
    }
    .days {
        letter-spacing: 0.05em;
        font-weight: normal;
        font-family: 'M PLUS 2', sans-serif;
    }
    .total {
        letter-spacing: 0.05em;
        font-weight: normal;
        font-family: 'M PLUS 2', sans-serif;
    }
`;
export default Userdatatext;
