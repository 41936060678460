import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import config from '../../../styles/setting/_config.json';
import Mode from '../../../providers/mode/Mode';

const { color } = config;
const Yrnmo = React.memo(({ year, number, month }) => {
    const mode = useRecoilValue(Mode);
    return (
        <Sdiv mode={mode.page} number={number}>
            <p className="month">{month}</p>
            <p className="year">{year}</p>
        </Sdiv>
    );
});
const Sdiv = styled.div`
    text-align: center;
    position: relative;
    width: fit-content;
    margin: auto;
    .month {
        font-family: 'M PLUS 2', sans-serif;
        color: ${color.black};
        font-size: 4rem;
        font-weight: 600;
        letter-spacing: 0.2em;
    }
    .year {
        font-family: 'M PLUS 2', sans-serif;
        margin-top: 16px;
        color: ${color.black};
        font-size: 2rem;
        font-weight: normal;
        letter-spacing: 0.2em;
        padding-bottom: 5px;
        border-bottom: 1px solid ${color.black};
        display: inline-block;
    }
    &::after {
        font-weight: normal;
        color: ${color.black};
        opacity: 0.15;
        font-size: ${(props) => (props.mode === 'input' ? '7rem' : ' 9.5rem ')};
        display: block;
        content: '${(props) => props.number}';
        position: absolute;
        bottom: 0;
        right: ${(props) => (props.mode === 'input' ? '-60%' : '-50% ')};
        font-family: 'M PLUS 2', sans-serif;
    }
`;
export default Yrnmo;
