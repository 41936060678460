import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import config from '../../../styles/setting/_config.json';

const { color } = config;

const Calendar = React.memo(({ day, wday }) => {
    return (
        <Sdiv>
            <FontAwesomeIcon icon={faCalendar} />
            <p>
                {day}
                <span>{wday}</span>
            </p>
        </Sdiv>
    );
});

const Sdiv = styled.div`
    display: inline-block;
    position: absolute;
    top: 32px;
    left: 13px;
    user-select: none;
    svg {
        font-size: 55px;
        color: ${color.white};
        stroke: ${color.black};
        stroke-width: 20px;
    }
    p {
        top: 55%;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        color: ${color.black};
        font-size: 1.6rem;
        font-weight: bold;
        white-space: nowrap;
        font-family: 'M PLUS 2', sans-serif;
        span {
            font-family: 'Kiwi Maru', serif;
            margin-left: 3px;
            font-size: 0.9rem;
            font-weight: 600;
        }
    }
`;

export default Calendar;
