import React from 'react';
import styled from 'styled-components';
import BSbutton from '../../../styles/BSbutton';

const Btn = React.memo(({ btnRole }) => {
    return <Sbutton type="submit">{btnRole}</Sbutton>;
});
const Sbutton = styled(BSbutton)`
    margin: auto;
    display: block;
    margin-top: 40px;
`;

export default Btn;
