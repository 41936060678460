import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AnimatePresence } from 'framer-motion';
import { Home } from '../pages/home/Home';
import Mode from '../../providers/mode/Mode';
import Login from '../pages/login/Login';
import Input from '../pages/input/Input';
import Attendancecontext from '../../providers/context/attendancecontext/Attendancecontext';
import Load from '../../providers/context/load/Load';
import Trans from '../../providers/context/trans/Trans';
import Loading from '../atoms/loading/Loading';
import Subcontent from '../templates/subcontent/Subcontent';

function Juncture() {
    useEffect(() => {
        const touchMoveHandler = (event) => {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        };

        document.body.addEventListener('touchmove', touchMoveHandler, { passive: false });

        return () => {
            document.body.removeEventListener('touchmove', touchMoveHandler);
        };
    }, []);
    const mode = useRecoilValue(Mode);
    let display = null;
    const [changeDate, setChangeDate] = useState({});
    const [dateEvent, setDateEvent] = useState('');
    const [load, setLoad] = useState(true);
    const [trans, setTrans] = useState(false);

    switch (mode.page) {
        case 'login':
            display = <Login />;
            break;
        case 'home':
            display = <Home />;
            break;
        case 'input':
            display = <Input />;
            break;
        case 'register':
            display = <Login />;
            break;
        default:
            display = null;
    }

    return (
        <Attendancecontext.Provider value={{ dateEvent, setDateEvent, changeDate, setChangeDate }}>
            <Load.Provider value={{ load, setLoad }}>
                <Trans.Provider value={{ trans, setTrans }}>
                    <AnimatePresence>{load && <Loading />}</AnimatePresence>
                    {display}
                    {(mode.page === 'home' || mode.page === 'input') && <Subcontent />}
                </Trans.Provider>
            </Load.Provider>
        </Attendancecontext.Provider>
    );
}

export default Juncture;
