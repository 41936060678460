import React from 'react';
import styled from 'styled-components';
import Column from '../column/Column';
import config from '../../../styles/setting/_config.json';

const { color } = config;
const Logininput = React.memo(({ userValue, passwordValue, onUserChange, onPasswordChange }) => {
    return (
        <Sdiv>
            <dl>
                <Column>
                    <label htmlFor="user">
                        <dt>User</dt>
                        <dd>
                            <input
                                type="text"
                                name="user"
                                id="user"
                                value={userValue}
                                onChange={onUserChange}
                            />
                        </dd>
                    </label>
                </Column>
                <Column>
                    <label htmlFor="pass">
                        <dt>Pass</dt>
                        <dd>
                            <input
                                type="password"
                                name="password"
                                id="pass"
                                value={passwordValue}
                                onChange={onPasswordChange}
                            />
                        </dd>
                    </label>
                </Column>
            </dl>
        </Sdiv>
    );
});
const Sdiv = styled.div`
    dl {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        align-items: center;
    }
    dt {
        font-size: 1.4rem;
        font-weight: 600;
        color: ${color.black};
        font-family: 'Kiwi Maru', serif;
    }
    dd {
        font-size: 1.4rem;
        font-weight: bold;
        color: ${color.black};
        font-family: 'M PLUS 2', sans-serif;
        margin-left: 16px;
        width: 100%;
    }
    label {
        padding: 0 25px;
        display: flex;
        width: 100%;
    }
    input {
        width: 100%;
        padding: 0;
        border: none;
        border-radius: 0;
        outline: none;
        background: none;
    }
`;
export default Logininput;
