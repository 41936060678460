import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Month from '../../atoms/month/Month';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';

function Relmonth() {
    const [prevMonth, setPrevMonth] = useState('');
    const [nextMonth, setNextMonth] = useState('');
    const [isRequesting, setIsRequesting] = useState(false);
    const { setDateEvent } = useContext(Attendancecontext);

    const handleRelMonthClick = async () => {
        if (isRequesting) {
            return;
        }
        setIsRequesting(true);
        try {
            const phpPath = '../php//date.php';
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { rel: 'rel' } }),
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setPrevMonth(data.prev);
                setNextMonth(data.next);
                setDateEvent((prevState) => !prevState);
            }
        } catch (error) {
            console.log('エラー:', error);
        } finally {
            setIsRequesting(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleRelMonthClick();
        }, 1000);
    }, []);

    return (
        <Sdiv>
            <Month onClick={handleRelMonthClick} month={prevMonth} rel="prev" />
            <Month onClick={handleRelMonthClick} month={nextMonth} rel="next" />
        </Sdiv>
    );
}

const Sdiv = styled.div`
    position: absolute;
    display: flex;
    width: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: space-between;
`;

export default Relmonth;
