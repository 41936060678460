import React, { useContext, useEffect } from 'react';
import Logincontent from '../../templates/logincontent/Logincontent';
import Load from '../../../providers/context/load/Load';

function Login() {
    const { load, setLoad } = useContext(Load);

    useEffect(() => {
        setTimeout(() => {
            if (load) {
                setLoad(false);
            }
        }, 2000);
    }, []);
    return <Logincontent />;
}

export default Login;
