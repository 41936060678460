import styled from 'styled-components';
import { rgba } from 'polished';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import config from '../../../styles/setting/_config.json';
import Mode from '../../../providers/mode/Mode';
import Trans from '../../../providers/context/trans/Trans';

const { color } = config;
const boxShadow = rgba(color.dark, 0.16);

function Rectangle({ children }) {
    const mode = useRecoilValue(Mode);
    const { trans } = useContext(Trans);
    return (
        <AnimatePresence>
            {trans || (
                <Sdiv
                    key=""
                    initial={{
                        width: '100vw',
                        backgroundColor: mode.page === 'home' ? color.cream : color.white,
                    }}
                    animate={{
                        width: '75px',
                        backgroundColor: mode.page === 'home' ? color.cream : color.white,
                    }}
                    exit={{
                        width: '100vw',
                        backgroundColor: mode.page === 'home' ? color.white : color.cream,
                    }}
                    transition={{
                        duration: 0.6,
                    }}
                    mode={mode.page}
                >
                    {children}
                </Sdiv>
            )}
        </AnimatePresence>
    );
}

const Sdiv = styled(motion.div)`
    top: 0;
    left: 0;
    width: 75px;
    height: 100%;
    box-shadow: 3px 0 20px ${boxShadow};
`;

export default Rectangle;
