import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import Mode from '../../../providers/mode/Mode';

const Logo = React.memo(({ size }) => {
    const mode = useRecoilValue(Mode);

    return (
        <Sdiv mode={mode.page} size={size}>
            <img src="../../../../images/logo.svg" alt="" />
        </Sdiv>
    );
});

const Sdiv = styled.div`
    ${({ mode }) =>
        mode !== 'login' && mode !== 'register'
            ? css`
                  position: absolute;
                  bottom: 24px;
                  left: 15px;
                  margin: auto;
              `
            : null}
    width: ${({ size }) => `${size}px`};
    img {
        user-select: none;
        pointer-events: none;
        width: 100%;
    }
`;

export default Logo;
