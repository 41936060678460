import styled from 'styled-components';
import { rgba } from 'polished';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import config from '../../../styles/setting/_config.json';
import Mode from '../../../providers/mode/Mode';
import Trans from '../../../providers/context/trans/Trans';

const { color } = config;
const boxShadow = rgba(color.dark, 0.16);

function Tryangle({ children }) {
    const mode = useRecoilValue(Mode);
    const { trans } = useContext(Trans);

    return (
        <AnimatePresence>
            {trans || (
                <Sdiv
                    key=""
                    initial={{ opacity: 0, transition: { delay: 0.2 } }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                    exit={{ opacity: 0 }}
                    transition={{
                        duration: 0.4,
                    }}
                    mode={mode.page}
                >
                    {children}
                </Sdiv>
            )}
        </AnimatePresence>
    );
}

const Sdiv = styled(motion.div)`
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    &::after {
        box-shadow: ${(props) =>
            props.mode === 'login' || props.mode === 'register'
                ? `0 -3px 10px ${boxShadow}`
                : `0 3px 10px ${boxShadow} inset`};
        width: 265px;
        height: 265px;
        content: '';
        transform: translate(50%, 50%) rotate(45deg);
        background-color: ${(props) => (props.mode === 'input' ? color.white : color.cream)};
        display: block;
    }
`;

export default Tryangle;
