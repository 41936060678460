import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faBan } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { useRecoilState } from 'recoil';
import config from '../../../styles/setting/_config.json';
import Mode from '../../../providers/mode/Mode';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';
import Edit from '../../../providers/edit/Edit';
import Trans from '../../../providers/context/trans/Trans';

const { color } = config;
const boxShadow = rgba(color.dark, 0.16);

const Editbtn = React.memo(({ rule, isView, setIsView }) => {
    const { changeDate } = useContext(Attendancecontext);
    const [mode, setMode] = useRecoilState(Mode);
    const [edit, setEdit] = useRecoilState(Edit);
    const { trans, setTrans } = useContext(Trans);

    let icon;
    let svgColor;
    switch (rule) {
        case 'pen':
            icon = <FontAwesomeIcon icon={faPen} />;
            svgColor = color.black;
            break;
        case 'trash':
            icon = <FontAwesomeIcon icon={faTrash} />;
            svgColor = color.white;
            break;
        case 'ban':
            icon = <FontAwesomeIcon icon={faBan} />;
            svgColor = color.black;
            break;
        default:
            break;
    }
    const ruleMenu = async () => {
        if (rule === 'pen') {
            setIsView((prevState) => !prevState);
            return;
        }
        if (rule === 'ban') {
            if (!trans) {
                setTrans(true);
            }
            if (edit) {
                setEdit(false);
            }
            setTimeout(() => {
                setMode({ ...mode, page: 'home' });
                setTrans(false);
            }, 600);
            return;
        }

        try {
            const phpPath = '../php//edit.php';
            const { id } = changeDate;
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { trash: { id } } }),
                credentials: 'include',
            });

            const data = await response.json();
            if (data.success) {
                if (edit) {
                    setEdit(false);
                }
                if (!trans) {
                    setTrans(true);
                }
                setTimeout(() => {
                    setMode({ ...mode, page: 'home' });
                    setTrans(false);
                }, 800);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };

    return (
        <Sdiv onClick={ruleMenu} rule={rule} svgColor={svgColor} isView={isView}>
            {icon}
        </Sdiv>
    );
});
const Sdiv = styled.div`
    box-shadow: 0 3px 20px ${boxShadow};
    width: 75px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    transition: 0.4s;
    ${(props) =>
        props.rule === 'pen' &&
        css`
            background-color: ${color.cream};
            border: 1px solid ${color.black};
            right: 15px;
            bottom: 20px;
            z-index: 2;

            ${props.isView &&
            css`
                box-shadow: 0 3px 10px ${boxShadow} inset;
            `}
        `}

    ${(props) =>
        props.rule === 'trash' &&
        css`
            background-color: ${color.darkRed};
            border: none;
            right: 15px;
            bottom: 20px;
            z-index: 1;
            opacity: 0;

            ${props.isView &&
            css`
                opacity: 1;
                right: 114px;
                bottom: 20px;
            `}
        `}

    ${(props) =>
        props.rule === 'ban' &&
        css`
            background-color: ${color.rightGray};
            border: 1px solid ${color.black};
            opacity: 0;
            right: 15px;
            bottom: 20px;
            z-index: 1;

            ${props.isView &&
            css`
                opacity: 1;
                right: 15px;
                bottom: 94px;
            `}
        `}

    svg {
        font-size: 18px;
        color: ${(props) => props.svgColor};
    }
`;

export default Editbtn;
