import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import Datecolumn from '../../molecules/datecolumn/Datecolumn';
import config from '../../../styles/setting/_config.json';
import Mode from '../../../providers/mode/Mode';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';

const { color } = config;
function Datecolumnslist() {
    const mode = useRecoilValue(Mode);

    const [dateData, setDateData] = useState([]);
    const { dateEvent } = useContext(Attendancecontext);

    let phpPath = '';

    if (mode.isHost) {
        phpPath = '../php//usersAttendance.php';
    } else {
        phpPath = '../php//attendance.php';
    }

    const setData = async () => {
        try {
            const response = await fetch(phpPath, { credentials: 'include' });
            if (response.ok) {
                const data = await response.json();
                const dataArray = Array.isArray(data) ? data : [data];
                setDateData(dataArray);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setData();
    }, [dateEvent]);

    return (
        <Sdiv>
            <div className="listWrap">
                {dateData.length > 0 && mode.isHost
                    ? dateData.map((data) => {
                          return (
                              <Datecolumn
                                  key={uuidv4()}
                                  id={data.id}
                                  user={data.user_name}
                                  days={`${data.attendance_count} days`}
                                  total={`${data.total_time}h`}
                              />
                          );
                      })
                    : dateData.map((data) => {
                          const time = `${data.entryTime}/${data.exitTime}-${data.breakTime}min`;
                          return (
                              <Datecolumn
                                  key={uuidv4()}
                                  id={data.id}
                                  day={`${data.day}日`}
                                  time={time}
                                  total={data.totalTime && `${data.totalTime}h`}
                              />
                          );
                      })}
            </div>
        </Sdiv>
    );
}

const Sdiv = styled.div`
    margin-top: 30px;
    height: 100%;
    width: 330px;
    border-top: 1px ${color.black} solid;
    border-bottom: 1px ${color.black} solid;
    padding: 15px;
    display: flex;
    transition: 0.4s;
    overflow: hidden;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    .listWrap {
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;
        margin: auto;
        align-items: center;
        width: fit-content;
        flex-direction: column;
        row-gap: 12px;
        height: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export default Datecolumnslist;
