import React from 'react';
import styled from 'styled-components';
import config from '../../../styles/setting/_config.json';

const { color } = config;
const Message = React.memo(({ message }) => {
    return (
        <Sdiv>
            {Object.keys(message).map((key) => (
                <p key={key}>{message[key]}</p>
            ))}
        </Sdiv>
    );
});
const Sdiv = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    p {
        color: ${color.red};
        font-size: 1.5rem;
        font-weight: normal;
        font-family: 'Kiwi Maru', serif;
        letter-spacing: 0.05em;
    }
`;
export default Message;
