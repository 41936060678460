import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../molecules/sidebar/Sidebar';
import Editarea from '../../molecules/editarea/Editarea';

function Subcontent() {
    return (
        <Sdiv>
            <Sidebar />
            <Editarea />
        </Sdiv>
    );
}
const Sdiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    width: 100dvw;
`;

export default Subcontent;
