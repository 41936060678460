import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import Juncture from './components/juncture/Juncture';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
    <RecoilRoot>
        <Juncture />
    </RecoilRoot>
    // </React.StrictMode>
);
