import React, { useContext, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useRecoilState } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import Inputcontent from '../../templates/inputcontent/Inputcontent';
import Datecontent from '../../templates/datecontent/Datecontent';
import config from '../../../styles/setting/_config.json';
import Message from '../../atoms/message/Message';
import Mode from '../../../providers/mode/Mode';
import Edit from '../../../providers/edit/Edit';
import Trans from '../../../providers/context/trans/Trans';

const { color } = config;
const Sbody = createGlobalStyle`
  body {
    background-color:${color.cream};
  }
`;
const Input = React.memo(() => {
    const [mode, setMode] = useRecoilState(Mode);
    const [edit, setEdit] = useRecoilState(Edit);
    const { trans, setTrans } = useContext(Trans);

    const [messe, setMesse] = useState('');
    const handleFormSubmit = async (e) => {
        const phpPath = '../php//input.php';
        e.preventDefault();

        try {
            const formData = new FormData(e.target);
            formData.append('data', JSON.stringify({ edit }));
            const response = await fetch(phpPath, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            const data = await response.json();
            if (data.success) {
                if (edit) {
                    setEdit(false);
                }
                if (!trans) {
                    setTrans(true);
                }
                setTimeout(() => {
                    setMode({ ...mode, page: 'home' });
                    setTrans(false);
                }, 600);
            } else {
                setMesse(data.error);
            }
        } catch (error) {
            console.log('エラー:', error);
        }
    };
    return (
        <Sarticle>
            <Sbody />
            <AnimatePresence>
                {trans || (
                    <motion.form
                        key=""
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: 0.4,
                        }}
                        onSubmit={handleFormSubmit}
                    >
                        {messe && <Message message={messe} />}
                        <Datecontent />
                        <Inputcontent />
                    </motion.form>
                )}
            </AnimatePresence>
        </Sarticle>
    );
});

const Sarticle = styled.article`
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    form {
        flex: 1;
        height: 100%;
    }
`;

export default Input;
