import React, { useState } from 'react';
import styled from 'styled-components';
import config from '../../../styles/setting/_config.json';

const { color } = config;

function Month({ month, rel, onClick }) {
    const phpPath = '../php//date.php';
    const [isRequesting, setIsRequesting] = useState(false);

    const setDate = async () => {
        if (isRequesting) {
            return;
        }

        setIsRequesting(true);
        try {
            const response = await fetch(phpPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { rel } }),
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                onClick(data);
            }
        } catch (error) {
            console.log('エラー:', error);
        } finally {
            setIsRequesting(false);
        }
    };
    return (
        <Sdiv onClick={setDate} rel={rel}>
            <p>{month}</p>
        </Sdiv>
    );
}

const Sdiv = styled.div`
    position: relative;
    width: fit-content;
    display: flex;
    position: relative;
    align-items: center;

    p {
        color: ${color.black};
        font-size: 2rem;
        font-weight: normal;
        font-family: 'M PLUS 2', sans-serif;
    }

    ${(props) =>
        props.rel === 'prev' &&
        `
    &::before,
    &::after {
      position: absolute;
      content: '';
      background-color: ${color.black};
      height: 8px;
      width: 1px;
      left: -8px;
    }

    &::before {
      transform: rotate(40deg);
      top: 20%;
    }

    &::after {
      top: 50%;
      transform: rotate(-40deg);
    }
  `}

    ${(props) =>
        props.rel === 'next' &&
        `
    &::before,
    &::after {
      position: absolute;
      content: '';
      background-color: ${color.black};
      height: 8px;
      width: 1px;
      right: -8px;
    }

    &::before {
      transform: rotate(-40deg);
      top: 20%;
    }

    &::after {
      top: 50%;
      transform: rotate(40deg);
    }
  `}
`;

export default Month;
