import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { useRecoilState } from 'recoil';
import config from '../../../styles/setting/_config.json';
import Mode from '../../../providers/mode/Mode';
import Attendancecontext from '../../../providers/context/attendancecontext/Attendancecontext';
import Trans from '../../../providers/context/trans/Trans';
import Load from '../../../providers/context/load/Load';

const { color } = config;
const boxShadow = rgba(color.dark, 0.16);

const Addbtn = React.memo(({ rule = 'add' }) => {
    const { setChangeDate } = useContext(Attendancecontext);
    const { trans, setTrans } = useContext(Trans);
    const { load, setLoad } = useContext(Load);

    useEffect(() => {
        setChangeDate('');
    }, []);
    const [mode, setMode] = useRecoilState(Mode);
    const changeMode = () => {
        if (mode.isHost) {
            setLoad(true);
            if (mode.page === 'register') {
                setMode({ ...mode, page: 'home' });
            } else {
                setMode({ ...mode, page: 'register' });
            }
            setTimeout(() => {
                setLoad(false);
            }, 2000);
        } else {
            if (!trans) {
                setTrans(true);
            }
            setTimeout(() => {
                setMode({ ...mode, page: 'input' });
                setTrans(false);
            }, 600);
        }
    };
    return (
        <Sdiv onClick={changeMode} mode={mode.page}>
            <span />
            <p>{rule}</p>
        </Sdiv>
    );
});
const Sdiv = styled.div`
    box-shadow: 0 3px 20px ${boxShadow};
    background-color: ${color.white};
    width: 70px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    bottom: 20px;
    z-index: 1;
    span {
        ${(props) =>
            props.mode === 'register'
                ? css`
                      display: none;
                  `
                : css`
                      width: 15px;
                      margin-right: 3px;
                      position: relative;
                      height: fit-content;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      &::before,
                      &::after {
                          content: '';
                          display: inline-block;
                          position: absolute;
                          background-color: ${color.black};
                          top: 50%;
                          transform: translateY(-50%);
                      }
                      &::before {
                          width: 2px;
                          height: 15px;
                      }
                      &::after {
                          width: 15px;
                          height: 2px;
                      }
                  `}
    }
    p {
        font-size: ${(props) => (props.mode === 'register' ? '1.8rem' : '2rem ')};
        font-weight: normal;
        color: ${color.black};
        font-family: 'M PLUS 2', sans-serif;
    }
`;

export default Addbtn;
